<template>
  <div class="mx-auto max-w-lg px-4 py-12">
    <div class="mb-4 w-full text-center">
      <span class="text-2xl font-bold">
        <template v-if="useTicketUserStore().gatekeeper_mode">
          Gate Keeper
        </template>
        <template v-else>
          Staff Information System
        </template>
      </span>
      <p class="text-sm">
        Ticketing System
      </p>
    </div>
    <div v-if="isInitialLoading">
      <div class="flex h-64 items-center justify-center text-white">
        <div class="w-full text-center">
          <div class="mb-5">
            <cgn-spinner />
          </div>
          Loading {{ ticketUserStore.count_total }} tickets
        </div>
      </div>
    </div>
    <div v-else-if="errorMessage">
      <div class="flex h-64 items-center justify-center bg-danger-500 text-white">
        <div class="text-center">
          <i-heroicons-solid:exclamation class="inline text-6xl" />
          <div>
            {{ errorMessage }}
          </div>
          <cgn-button color-white class="mt-5" @click="errorMessage = ''">
            Close
          </cgn-button>
        </div>
      </div>
    </div>
    <div v-else-if="successMessage">
      <div class="bg-success-500 py-20 text-center text-white">
        <div class="mb-5 text-6xl">
          <i-heroicons-solid:check class="inline" />
        </div>
        <div>
          {{ successMessage }}
        </div>
        <div class="my-3 text-2xl">
          {{ curTicket?.disp }}
        </div>
        <div class="my-3 text-3xl">
          {{ curTicket?.cust }}
        </div>
        <cgn-button color-white class="mt-5" @click="successMessage = ''">
          Close
        </cgn-button>
      </div>
    </div>
    <div v-else-if="customContent">
      <div class="prose dark:prose-dark" v-html="customContent" />
      <cgn-button color-white class="mt-5" @click="customContent = ''">
        Close
      </cgn-button>
    </div>
    <div v-else class="rounded-lg bg-white px-8 py-6 shadow-lg dark:bg-slate-800">
      <div v-if="!isScanning">
        <cgn-button
          :color-info="!!useTicketUserStore().gatekeeper_mode"
          :color-warning="!useTicketUserStore().gatekeeper_mode"
          fullwidth is-label @click="startScan"
        >
          <i-heroicons-solid:camera class="inline text-6xl" />
        </cgn-button>
        <cgn-button color-primary fullwidth size-large class="mt-20" url="/findTicket">
          <i-bx:search class="inline" />
          Search
        </cgn-button>
        <form class="mt-10" @submit.prevent="enterManualCode">
          <div class="text-center text-sm">
            Manual code
          </div>
          <div class="flex items-baseline justify-between">
            <div class="w-2/5">
              <cgn-form-input-text v-model="manualcode1" />
            </div>
            <div class="w-1/4 text-center">
              /
            </div>
            <div class="w-2/5">
              <cgn-form-input-text v-model="manualcode2" />
            </div>
          </div>
          <cgn-button color-secondary fullwidth>
            Use Code
          </cgn-button>
        </form>
      </div>
      <div v-else>
        <div class="mb-2 h-3/4 w-full">
          <cgn-qrcode-scan @decoded="onDecode" />
        </div>
        <cgn-button color-brand fullwidth is-label @click="isScanning = false">
          <i-heroicons-solid:x class="inline text-6xl" />
        </cgn-button>
      </div>
    </div>
  </div>
  <div class="mx-auto max-w-lg px-4">
    <div>
      {{ ticketUserStore.baseURL }}
      <span class="float-right">
        {{ ticketUserStore.count_used }} / {{ ticketUserStore.count_total }}
      </span>
    </div>
    <cgn-button is-label color-brand fullwidth class="mt-3" @click="forceReload()">
      Reload
    </cgn-button>
    <cgn-form-checkbox v-model="useTicketUserStore().gatekeeper_mode" class="mt-6" label="Gatekeeper mode" />
  </div>
</template>

<script setup lang="ts">
import { CognitoTime } from '~cognito/models/Cognito/Time'
import { findTicket, getRecentTicketChanges, getTicketContent, sendScannedTicket, setUsed } from '~/database/tickets'

const ticketUserStore = useTicketUserStore()
const isScanning = ref(false)
const isInitialLoading = ref(false)
const decodedInfo = ref('')
const manualcode1 = ref('')
const manualcode2 = ref('')
const curTicket = ref(null)

const successMessage = ref('')
const errorMessage = ref('')
const customContent = ref('')

const startScan = () => {
  successMessage.value = ''
  errorMessage.value = ''
  customContent.value = ''
  isScanning.value = true
}

const forceReload = async () => {
  ticketUserStore.last_updated = '' // Force refresh
  isInitialLoading.value = true
  await getRecentTicketChanges(true)
  isInitialLoading.value = false
}
const enterManualCode = async () => {
  // Check the codes
  // const check = 'gWXa/f21c'
  const check = `${manualcode1.value}/${manualcode2.value}`
  curTicket.value = await findTicket(check)

  manualcode1.value = ''
  manualcode2.value = ''

  if (!curTicket.value) {
    errorMessage.value = 'Ticket was not found'
    return
  }
  if (!useTicketUserStore().gatekeeper_mode) {
    customContent.value = (await getTicketContent(curTicket.value.chk))?.content
    if (!customContent.value) {
      customContent.value = 'No information on this entrant'
    }

    return
  }
  if (curTicket.value.usd) {
    errorMessage.value = `Ticket was used ${new CognitoTime(curTicket.value.usd).toHumanTimeString()}`
    return
  }
  successMessage.value = 'Success'
  setUsed(curTicket.value)
}

const onDecode = (data) => {
  decodedInfo.value = data
  isScanning.value = false

  // Split up of the form https://gatedemo.d.jm1.me/tkgt/gWXa/c/d6f0
  const datamatch = /tkgt\/(.*)\/c\/(.*)/gm
  const matches = [...decodedInfo.value.matchAll(datamatch)]
  if (matches.length && matches[0].length == 3) {
    manualcode1.value = matches[0][1]
    manualcode2.value = matches[0][2]
    enterManualCode()
  } else {
    errorMessage.value = 'Could not find a valid code'
  }
}

onMounted(async () => {
  if (!ticketUserStore.isLoggedIn()) {
    useRouter().replace('/login')
  }
  if (ticketUserStore.last_updated) {
    // Check if not updated today
    if (!new CognitoTime(ticketUserStore.last_updated).isSameDay(new CognitoTime())) {
      ticketUserStore.last_updated = '' // Force refresh
    }
  }
  if (ticketUserStore.last_updated == '') {
    forceReload()
  }
  getRecentTicketChanges()
  useIntervalFn(sendScannedTicket, 5000)
  useIntervalFn(getRecentTicketChanges, 30000)
})
</script>
