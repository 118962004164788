import type { DBSchema } from 'idb'
import { openDB } from 'idb'
import { $ticketserveraxios } from '~/plugins/ticketserverapi'
import { CognitoTime } from '~cognito/models/Cognito/Time'

class TicketInfo {
  cust: string
  eml: string
  disp: string
  chk: string
  usd: string

  constructor() {
    this.cust = ''
    this.eml = ''
    this.disp = ''
    this.chk = ''
    this.usd = ''
  }
}

interface TicketDB extends DBSchema {
  ticket: {
    value: TicketInfo
    key: string
    indexes: {
      'by-chk': string
    }
  }
  usedUnsent: {
    value: {
      chk: string
      usd: string
    }
    key: string
    indexes: {
      'by-chk': string
    }
  }
}

const ticket_db = openDB<TicketDB>('ticket-db', 1, {
  upgrade(ticket_db) {
    const ticketStore = ticket_db.createObjectStore('ticket', {
      keyPath: 'chk',
    })
    ticketStore.createIndex('by-chk', 'chk')
    const usedUnsentStore = ticket_db.createObjectStore('usedUnsent', {
      keyPath: 'chk',
    })
    usedUnsentStore.createIndex('by-chk', 'chk')
  },
})

const countUnsent = async () => {
  const db = await ticket_db
  const count = await db.count('usedUnsent')
  useTicketUserStore().count_to_send = count
}

const findTicket = async (key: string) => {
  const res = (await ticket_db).get('ticket', key)
  return res
}

const searchTickets = async (key: string) => {
  if (key.length < 2) {
    return []
  }
  const db = await ticket_db
  const matches = []
  const tx = db.transaction('ticket', 'readonly')
  let cursor = await tx.store.openCursor()
  while (cursor) {
    let ticketMatch = false
    ticketMatch = cursor.value.cust.toUpperCase().includes(key.toUpperCase())
    if (!ticketMatch) {
      ticketMatch = cursor.value.disp.toUpperCase().includes(key.toUpperCase())
    }
    if (!ticketMatch) {
      ticketMatch = cursor.value.eml.toUpperCase().includes(key.toUpperCase())
    }

    if (ticketMatch) {
      matches.push(cursor.value)
      if (matches.length >= 50) {
        break
      }
    }
    cursor = await cursor.continue()
  }

  return matches
}

const setUsed = async (ticket: TicketInfo) => {
  const scannedAt: string = new CognitoTime().toISO8601String()

  ;(await ticket_db).put('usedUnsent', {
    chk: ticket.chk,
    usd: scannedAt,
  })
  ticket.usd = scannedAt
  ;(await ticket_db).put('ticket', JSON.parse(JSON.stringify(ticket)))
  countUnsent()
}

const sendScannedTicket = async () => {
  const ticket = await (await ticket_db).transaction('usedUnsent').store.openCursor()
  if (!ticket?.key) {
    return
  }
  const res = await $ticketserveraxios.post('/api/v1/ticket/ticket/setScanned', { ticket: ticket.value })
  if (res.data.success) {
    (await ticket_db).delete('usedUnsent', ticket.value.chk)
  }
  countUnsent()
}

const is_fetching = ref(false)

const getRecentTicketChanges = async (force: boolean = false) => {
  if (force) {
    is_fetching.value = false
  }
  if (is_fetching.value) {
    return
  }
  is_fetching.value = true
  const db = await ticket_db
  const res = await $ticketserveraxios.post('/api/v1/ticket/ticket/recentChanges', {
    since: useTicketUserStore().last_updated,
  })
  if (res.data.success) {
    useTicketUserStore().count_total = res.data.ticket_count
    useTicketUserStore().count_used = res.data.used_count

    // Clear if necessary
    if (res.data.clear) {
      db.clear('ticket')
    }

    // Add new tickets
    res.data.tickets.forEach((ticket: any) => {
      db.put('ticket', ticket)
    })

    // Add new used
    res.data.used.forEach(async (usedTicket) => {
      const ticket = await findTicket(usedTicket.chk)
      if (!ticket) {
        return
      }
      ticket.usd = usedTicket.usd
      db.put('ticket', ticket)
    })

    // Set time to refresh
    useTicketUserStore().last_updated = res.data.timestamp

    // Go through all locally used tickets and apply
    ;(await db.getAll('usedUnsent')).forEach(async (usedTicket) => {
      const ticket = await findTicket(usedTicket.chk)
      if (!ticket) {
        return
      }
      ticket.usd = usedTicket.usd
      db.put('ticket', ticket)
    })
    countUnsent()
  } else {
    useToastStore().addToast(res.data.message, 'fail', 3000)
  }
  is_fetching.value = false
}

const getTicketContent = async (ticket: TicketInfo): Promise<{
  content: string
}> => {
  const res = await $ticketserveraxios.post('/api/v1/ticket/ticket/customContent', { ticket })
  return res.data
}

export { TicketInfo, findTicket, searchTickets, setUsed, sendScannedTicket, getRecentTicketChanges, getTicketContent }
