import axios from 'axios'
import { acceptHMRUpdate, defineStore } from 'pinia'

export const useTicketUserStore = defineStore({
  id: 'ticketUser',

  state: () => {
    return {
      access_token: '',
      refresh_token: '',
      redirect_after_login: '/',
      oauth2_state: '',
      auth_fingerprint: '',
      user: {},
      baseURL: '',
      link_active: true,
      count_to_send: 0,
      count_used: 0,
      count_total: 0,
      last_updated: '',
      gatekeeper_mode: 1,
    }
  },

  actions: {
    getAuthFingerprint(): string {
      if (!this.auth_fingerprint) {
        this.auth_fingerprint = useUserStore().getAuthFingerprint()
      }
      return this.auth_fingerprint
    },
    isLoggedIn(): boolean {
      return !!this.access_token
    },
    async sendLoginLink(server: string, email: string): Promise<{
      success: boolean
      message: string
    }> {
      this.auth_fingerprint = useUserStore().getAuthFingerprint()
      this.baseURL = server
      const res = await axios.post(`${this.baseURL}/api/v1/cognito/user/sendLoginLink`, {
        email,
        fingerprint: this.auth_fingerprint,
        code_only: true,
      })
      return res.data
    },
    async checkRecoveryCode(data: {
      email: string
      code: string
    }): Promise<{
      access_token: string
      refresh_token: string
      message: string
    }> {
      const res = await axios.get(`${this.baseURL}/api/v1/cognito/user/loginRecoveryCode`, {
        params: {
          email: data.email,
          code: data.code,
          fingerprint: this.auth_fingerprint,
        },
      })
      if (res.data.tokens) {
        this.setAccessToken(res.data.tokens.access_token)
        this.setRefreshToken(res.data.tokens.refresh_token)
      }
      return res.data
    },
    setAccessToken(token: string) {
      this.access_token = token
    },
    setRefreshToken(token: string) {
      this.refresh_token = token
    },
    setUser(user: any) {
      this.user = user
    },
    setRedirectAfterLogin(path: string) {
      this.redirect_after_login = path
    },
    logout() {
      this.access_token = ''
      this.refresh_token = ''
      this.user = {
        id: 0,
        first_name: '',
        last_name: '',
        email: '',
        mobile_phone: '',
      }
    },
  },

  persist: true,
  share: {
    enable: true,
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useTicketUserStore, import.meta.hot))
}
